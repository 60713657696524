/* @flow */
import type { SearchMode } from '../../../util/rea-urls';
import type { Channel } from '../../Channel';
import type { ResolvedLocality } from '../../lexa';
import type { SearchPropertyType } from '../../propertyType';
import type { BreadcrumbItem } from '../types';
import { QueryFilters, SearchQuery } from '../../../util/rea-urls';
import getLocationUrlValue from './getLocationUrlValue';

const SearchModeTitles = {
    'auction-times': 'Auction',
    list: 'List',
    map: 'Map',
    'inspection-times': 'Inspection',
};

export default function getSearchModeItem(
    channel: Channel,
    localities: Array<ResolvedLocality>,
    propertyTypes: Array<SearchPropertyType>,
    searchMode: SearchMode
): BreadcrumbItem {
    const locations = getLocationUrlValue(localities);
    return {
        to: new SearchQuery({
            channel,
            filters: new QueryFilters({
                location: { searchLocations: locations },
                propertyTypes: propertyTypes[0] ? [propertyTypes[0]] : [],
            }),
            searchMode: searchMode,
        }).toUrl(),
        title: SearchModeTitles[searchMode],
    };
}
