/* @flow */
export const getBaseUrl = (url: string) => {
    const urlParts = url.split(/[?&#]/);
    return urlParts[0];
};

export const getQueryParams = (url: string) => {
    if (url.includes('?')) {
        const urlExtensions = url.split('?')[1];
        return `&${urlExtensions.split('#')[0]}`;
    }

    return '';
};

export const getAnchor = (url: string) => {
    if (url.includes('#')) {
        const urlParts = url.split('#');
        return `#${urlParts[urlParts.length - 1]}`;
    }
    return '';
};
