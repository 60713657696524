/* @flow */
import getFragmentData from '../../../util/getFragmentData';
import { compose3 as compose } from '../../../util/functional/compose';
import type {
    GetResidentialListing,
    ResidentialListing,
    ResidentialListingViewConfiguration,
} from '../../../models/lexa';
import DetailsMedRec from './DetailsMedRec.graphql';

export type AdaptedProps = {|
    showSidebarMedRec: boolean,
|};

type RequiredProps = {
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter = ({ viewConfiguration }: RequiredProps): AdaptedProps => {
    return {
        showSidebarMedRec: !!viewConfiguration.details.adverts.sidebarMedRec,
    };
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, AdaptedProps>(
    dataAdapter,
    getFragmentData(DetailsMedRec),
    (data) => data.getListing()
);
