/* @flow */
import React from 'react';
import type { ComponentType } from 'react';
import getTealiumLoader from '../../../client/tracking/tealium/loader';
import appLogger from '../../../util/logging/appLogger';
import type { TealiumLoader } from '../../../client/tracking/tealium/loader';
import type { GetTrackingData } from '../../../models/lexa';
import './styles.scss';
import { getUserData } from '@realestate.com.au/iglu-data';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';
import { APP_NAME } from '../adParams';
import { ABTestDeprecated, TrackExperiment } from '../../../experiments';

export type Props = {
    data: GetTrackingData,
    showRenovationCalculator: boolean,
    loadRenoCalc: () => Promise<*>,
    tealiumLoader: () => TealiumLoaderWrapper,
    page: string,
};

export type RenovationCalculatorProps = {
    appName: string,
    tealiumLoader: () => TealiumLoaderWrapper,
    dataLayer: Object,
};

export type TealiumLoaderWrapper = {
    load: () => TealiumLoader,
};

type State = {
    PropertyRenovationCalculator: ?ComponentType<RenovationCalculatorProps>,
};
export default class RenovationCalculator extends React.Component<Props, State> {
    state = {
        PropertyRenovationCalculator: null,
    };

    static defaultProps = {
        loadRenoCalc: () => import(/* webpackChunkName: "renovation-calculator" */ '@media/renovation-calculator'),
        tealiumLoader: (): TealiumLoaderWrapper => ({
            load: () => getTealiumLoader,
        }),
    };

    componentDidMount() {
        this.props
            .loadRenoCalc()
            .then((Calc) => {
                const { PropertyRenovationCalculator } = Calc;
                this.setState({ PropertyRenovationCalculator });
                return Calc;
            })
            .catch((err) => {
                appLogger.error('error loading renovation calc')(err);
            });
    }

    render() {
        const { data, showRenovationCalculator, page } = this.props;
        const { PropertyRenovationCalculator } = this.state;
        const trackingData = data.getTrackingData();
        return showRenovationCalculator ? (
            <div id="renovation-calculator" className="renovation-calculator">
                {
                    /* $FlowFixMe This comment suppresses an error found
                     * when upgrading Flow to v0.103.0. To view the
                     * error, delete this comment and run Flow. */
                    trackingData && PropertyRenovationCalculator && (
                        <TrackExperiment>
                            {(trackExperiment) => (
                                <ErrorBoundaryNewRelicReporter component="RenovationCalculator">
                                    <PropertyRenovationCalculator
                                        appName={APP_NAME}
                                        tealiumLoader={this.props.tealiumLoader}
                                        dataLayer={{
                                            ...trackingData,
                                            ...getUserData()(),
                                        }}
                                        trackExperiment={trackExperiment}
                                        page={page}
                                        abTest={ABTestDeprecated}
                                    />
                                </ErrorBoundaryNewRelicReporter>
                            )}
                        </TrackExperiment>
                    )
                }
            </div>
        ) : null;
    }
}
