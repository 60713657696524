/* @flow */
import type { BreadcrumbItem } from './types';
import type { Address } from '../lexa';
import type { NewHomesChannelType } from '../NewHomesChannel';
import NEW_HOMES_CHANNEL from '../NewHomesChannel';
import NewHomesSearchQuery from '../../util/rea-urls/lib/models/NewHomesSearchQuery';

const { NEW_APARTMENTS, NEW_LAND_ESTATES, NEW_HOMES, HOUSE_AND_LAND_PACKAGES } = NEW_HOMES_CHANNEL;

export const propertyTypeToNewHomesChannel = {
    'project-apartment': NEW_APARTMENTS,
    'project-estate': NEW_LAND_ESTATES,
};

const channelTitles = {
    [NEW_APARTMENTS]: 'New Apartments',
    [NEW_LAND_ESTATES]: 'New Land Estates',
    [NEW_HOMES]: 'New Homes',
    [HOUSE_AND_LAND_PACKAGES]: 'House and Land Packages',
};

export const getNewHomesChannelItem = (channel: NewHomesChannelType): BreadcrumbItem => {
    return {
        to: new NewHomesSearchQuery(channel).toCanonicalUrl(),
        title: channelTitles[channel],
        isCanonicalUrl: true,
    };
};

export const getNewHomesStateItem = (channel: NewHomesChannelType, { state }: Address): BreadcrumbItem => {
    return {
        to: new NewHomesSearchQuery(channel, { state }).toCanonicalUrl(),
        title: state.toUpperCase(),
        isCanonicalUrl: true,
    };
};

export const getNewHomesSuburbItem = (
    channel: NewHomesChannelType,
    { state, suburb, postcode }: Address
): BreadcrumbItem => {
    return {
        to: new NewHomesSearchQuery(channel, { state, suburb, postcode }).toCanonicalUrl(),
        title: suburb,
        isCanonicalUrl: true,
    };
};
