/* @flow */

import type { ResidenceSchemaMarkup } from './types';
import type { LexaListing } from '../lexa';

export default ({
    address: {
        suburb,
        state,
        postcode,
        display: { shortAddress },
    },
}: LexaListing): ?ResidenceSchemaMarkup => {
    if (!shortAddress) return null;
    return {
        '@context': 'http://schema.org',
        '@type': 'Residence',
        address: {
            '@type': 'PostalAddress',
            addressLocality: suburb,
            addressRegion: state.toUpperCase(),
            postalCode: postcode,
            streetAddress: shortAddress,
        },
        name: shortAddress,
    };
};
