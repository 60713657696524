/* @flow */
import type { Channel } from '../Channel';
import type { BreadcrumbItem } from './types';

const channelTitles = {
    buy: 'Buy',
    sold: 'Sold',
    rent: 'Rent',
};

export default function getChannelItem(channel: Channel): BreadcrumbItem {
    return {
        to: `/${channel}/`,
        title: channelTitles[channel],
    };
}
