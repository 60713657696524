/* @flow */
import type { BreadcrumbItem } from './types';
import type { Channel } from '../Channel';
import type { Address, LexaPropertyType } from '../lexa';
import SearchQuery from '../../util/rea-urls/lib/models/SearchQuery';
import QueryFilters from '../../util/rea-urls/lib/models/QueryFilters';

const lexaToSearchPropertyTypes = {
    house: 'house',
    unit: 'unit apartment',
    apartment: 'unit apartment',
    townhouse: 'townhouse',
    villa: 'villa',
    land: 'land',
    acreage: 'acreage',
    rural: 'rural',
    unitblock: 'unitblock',
    retire: 'retire',
};

export default (
    channel: Channel,
    { state, suburb, postcode }: Address,
    { id: propertyTypeId, display: propertyTypeDisplay }: LexaPropertyType
): ?BreadcrumbItem => {
    const mappedPropertyType = lexaToSearchPropertyTypes[propertyTypeId];
    return mappedPropertyType
        ? {
              to: new SearchQuery({
                  channel,
                  filters: new QueryFilters({
                      location: { searchLocations: [`${suburb}, ${state} ${postcode}`] },
                      propertyTypes: [mappedPropertyType],
                  }),
                  searchMode: 'list',
              }).toUrl(),
              title: propertyTypeDisplay,
          }
        : null;
};
