/* @flow */
import type { NewHomesChannelType } from '../../../../models/NewHomesChannel';
import { PRODUCTION_DESKTOP_HOST } from '../../../../config';

type Location = {
    state?: string,
    suburb?: string,
    postcode?: string,
};

class NewHomesSearchQuery {
    channel: NewHomesChannelType;
    location: Location;

    constructor(channel: NewHomesChannelType, location: ?Location) {
        this.channel = channel;
        this.location = location || {};
    }

    toCanonicalUrl(): string {
        return `https://${PRODUCTION_DESKTOP_HOST}${this.toPathname()}`;
    }

    toPathname(): string {
        const { state, suburb, postcode } = this.location;
        const urlSlug = [suburb, state, postcode]
            .filter((i) => !!i)
            .join(' ')
            .replace(/\W+/g, '-');
        const components = [this.channel, urlSlug].filter((item) => !!item);
        return `/${components.join('/')}/`.toLowerCase();
    }
}

export default NewHomesSearchQuery;
