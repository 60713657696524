/* @flow */

import React from 'react';
import type { Element } from 'react';
import './styles.scss';

type Props = {
    children: Element<*>[],
};

const DividedContent = ({ children }: Props) => <div className="divided-content">{children}</div>;

export default DividedContent;
