/* @flow */
import React from 'react';
import { LAYOUT_DEFINITION } from '../../../client/device-capability';
import { Ad } from '@media/ad-kit';
import type { GetTrackingData } from '../../../models/lexa/getTrackingData';
import type { AdType } from '@media/ad-kit';
import { APP_NAME } from '../adParams';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';
import useLayout from '../useLayout';
import useAdKey from '../useAdKey';
import adaptLexaData from './adaptLexaData';
import type { GetResidentialListing } from '../../../models/lexa/getListing';
import { getPdpPrebidNativeSize } from '@media/advert-configs-plw';

type Props = {
    data: GetTrackingData & GetResidentialListing,
    className?: string,
    Advert?: AdType,
};

const { L, XL } = LAYOUT_DEFINITION;

const DetailsMedRec1Ad = ({ data, className = '', Advert = Ad }: Props) => {
    const position = 'medrec1';
    const trackingData = data.getTrackingData();
    const layout = useLayout(trackingData);
    const adKey = useAdKey(position, trackingData);
    const { showSidebarMedRec } = adaptLexaData(data);

    return (
        showSidebarMedRec &&
        adKey &&
        (layout === L || layout === XL) && (
            <ErrorBoundaryNewRelicReporter component="DetailsMedRec1Ad">
                <Advert
                    adKey={adKey}
                    appName={APP_NAME}
                    position={position}
                    id="advert-medrec1"
                    className={`advert ${className}`.trim()}
                    dataLayer={trackingData}
                    sizes={['300x250', '300x300']}
                    renderSizes={['120x600', '160x600']}
                    autoHide={true}
                    isPrebid={true}
                    renderPrebidNativeSize={getPdpPrebidNativeSize(false, position)}
                    customParams={{ posttags: 'adkit', hb: '1' }}
                />
            </ErrorBoundaryNewRelicReporter>
        )
    );
};

export default DetailsMedRec1Ad;
