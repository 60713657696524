/* @flow */
import React from 'react';
import Dora from '../DoraExplorer.svg';
import styled from 'styled-components';
import { ArrowRightMd, spacingHelper, P, Card } from '@rea-group/construct-kit-core';
import { parseLexaToHTML } from '../../../util/string/parseLexaToHTML';

type Props = {
    title: ?string,
    suburbProfileUrl: ?string,
};

const DoraExplorer = styled.img`
    width: 40px;
    height: 40px;
    margin: ${spacingHelper('small')};
`;

const ArrowContainer = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;
    padding: ${spacingHelper('small small small medium')};
`;

const InsightsCTA = styled.div`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.textPrimary};
    overflow: hidden;
    margin: 0 auto 1.875em auto;
    &:visited a {
        color: ${({ theme }) => theme.palette.textPrimary};
        text-decoration: none;
    }
`;

const MarketInsights = ({ title, suburbProfileUrl }: Props) => {
    if (!title || !suburbProfileUrl) return null;
    return (
        <Card>
            <InsightsCTA as="a" href={suburbProfileUrl} aria-label="Explore the neighbourhood">
                <DoraExplorer src={Dora} alt="Research suburbs" />
                <div>
                    <P variant="body02">{parseLexaToHTML(title)}</P>
                </div>
                <ArrowContainer>
                    <ArrowRightMd />
                </ArrowContainer>
            </InsightsCTA>
        </Card>
    );
};

export default MarketInsights;
