/* @flow */
import type { BreadcrumbItem } from './types';
import type { ExternalBreadcrumbItem, InternalBreadcrumbItem } from '../../components/breadcrumb/types';

export const transformToExternalBreadcrumbItem = ({ to, title }: BreadcrumbItem): ExternalBreadcrumbItem => ({
    href: to,
    label: title,
    external: true,
});

export const transformToInternalBreadcrumbItem = ({ to, title }: BreadcrumbItem): InternalBreadcrumbItem => ({
    href: to,
    label: title,
    external: false,
});
