/* @flow */
import React, { useContext } from 'react';
import { Ad, ScriptAd } from '@media/ad-kit';
import type { AdType, ScriptAdType } from '@media/ad-kit';
import { LAYOUT_DEFINITION } from '../../../client/device-capability';
import ChannelContext from '../../../util/ChannelContext';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';
import type { GetTrackingData } from '../../../models/lexa';
import { APP_NAME } from '../adParams';
import useLayout from '../useLayout';
import useAdKey from '../useAdKey';

const { L, XL } = LAYOUT_DEFINITION;

export type Props = {
    data: GetTrackingData,
    Advert?: AdType,
    ScriptAdvert?: ScriptAdType,
};

const FooterCalculator = ({ data, ScriptAdvert = ScriptAd, Advert = Ad }: Props) => {
    const position = 'footercalc';
    const channel = useContext(ChannelContext);
    const trackingData = data.getTrackingData();
    const adKey = useAdKey(position, trackingData);
    const layout = useLayout(trackingData);

    const adProps = {
        id: 'advert-footercalc',
        appName: APP_NAME,
        className: 'advert',
        position,
        dataLayer: trackingData,
        sizes: ['640x200', '680x264'],
        customParams: { rb: 'true', posttags: 'adkit' },
    };

    return (
        adKey &&
        (layout === L || layout === XL) && (
            <ErrorBoundaryNewRelicReporter component="FooterCalculator">
                {channel === 'buy' || channel === 'sold' ? (
                    <ScriptAdvert adKey={adKey} {...adProps} />
                ) : (
                    <Advert adKey={adKey} {...adProps} />
                )}
            </ErrorBoundaryNewRelicReporter>
        )
    );
};

export default FooterCalculator;
