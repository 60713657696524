/* @flow */
import React from 'react';
import type { GetTrackingData } from '../../../models/lexa';
import type { AdType } from '@media/ad-kit';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';
import { LAYOUT_DEFINITION } from '../../../client/device-capability';
import { Ad } from '@media/ad-kit';
import useLayout from '../useLayout';
import useAdKey from '../useAdKey';
import { APP_NAME } from '../adParams';

const { L, XL } = LAYOUT_DEFINITION;

type Props = {
    data: GetTrackingData,
    showWidget: boolean,
    pos: number,
    Advert?: AdType,
};

export default ({ data, showWidget, pos, Advert = Ad }: Props) => {
    const position = `widget${pos}`;
    const trackingData = data.getTrackingData();
    const adKey = useAdKey(position, trackingData);
    const layout = useLayout(trackingData);

    return (
        showWidget &&
        (layout === L || layout === XL) &&
        adKey && (
            <ErrorBoundaryNewRelicReporter component="WidgetAd">
                <Advert
                    adKey={adKey}
                    id={`advert-${position}`}
                    appName={APP_NAME}
                    position={position}
                    className={'advert advert__sticky-sidebar--margin-below'}
                    dataLayer={trackingData}
                    sizes={['300x100']}
                    autoHide={true}
                    customParams={{ posttags: 'adkit' }}
                />
            </ErrorBoundaryNewRelicReporter>
        )
    );
};
