/* @flow */
import React from 'react';
import Link from '@rea-argonaut/link';
import { Breadcrumbs } from '@realestate.com.au/consumer-expansion-pack';
import { spacingHelper } from '@rea-group/construct-kit-core';
import type { InternalBreadcrumbItem, ExternalBreadcrumbItem } from './types';
import styled from 'styled-components';

type BreadcrumbItem = InternalBreadcrumbItem | ExternalBreadcrumbItem;

const StyledBreadcrumbs = styled(Breadcrumbs)`
    padding: ${spacingHelper('twoExtraSmall')} 0;
`;

type Props = {
    items: Array<BreadcrumbItem>,
};

class View extends React.Component<Props> {
    render() {
        const { items } = this.props;
        const breadcrumbItems = items.map((item) => {
            const { external, href, label } = item;
            return { href: href, label: label, linkAs: external ? 'a' : Link };
        });
        return items.length > 0 ? <StyledBreadcrumbs breadcrumbItems={breadcrumbItems} /> : null;
    }
}

export default View;
