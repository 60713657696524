/* @flow */
import React from 'react';
import Dora from '../DoraExplorer.svg';
import SectionHeading from '../../details/section-heading';
import { P, Link, Inline } from '@rea-group/construct-kit-core';
import { StyledCard, TitleLink } from './styles';

type Props = {
    title: ?string,
    description: ?string,
    suburbProfileUrl: ?string,
    buttonText: ?string,
    className?: string,
};

const MarketInsights = ({ title, description, suburbProfileUrl, buttonText, className = '' }: Props) => {
    if (!title || !description || !suburbProfileUrl) return null;

    return (
        <div className={`details-market-insights ${className}`}>
            <SectionHeading>
                <TitleLink href={suburbProfileUrl}>{title}</TitleLink>
            </SectionHeading>
            <StyledCard borderRadius="medium">
                <Inline gap="extraSmall" grow={false}>
                    <img width="40px" height="40px" src={Dora} alt="Research suburbs" />
                    <P variant="body02">{description}</P>
                </Inline>
                <Link variant="button-outline" href={suburbProfileUrl} fullWidth={true} sizeVariant="medium">
                    <P variant="subtitle01">{buttonText}</P>
                </Link>
            </StyledCard>
        </div>
    );
};

export default MarketInsights;
