/* @flow */
import React, { useState } from 'react';
import { LAYOUT_DEFINITION } from '../../../client/device-capability';
import type { GetTrackingData } from '../../../models/lexa';
import './styles.scss';
import type { AdWithFormType } from '@media/ad-kit';
import { AdWithForm } from '@media/ad-kit';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';
import appLogger from '../../../util/logging/appLogger';
import { APP_NAME } from '../adParams';
import useLayout from '../useLayout';
import useAdKey from '../useAdKey';
import clientStorage from '../../../util/clientStorage';
import { PREFILL_DATA_KEY } from '../../details/contact-agent/contact-agent-form/enquiry-prefill/actions';
import { createBrowserUser } from '@realestate.com.au/user';
import { ABTestDeprecated } from '../../../experiments';
import { useExperiment } from '@rea-argonaut/experiment';
import { FeatureToggle } from '../../toggles';
import { getSrpPrebidNativeSize } from '@media/advert-configs-plw';

const { S, L, XL } = LAYOUT_DEFINITION;

export type Props = {
    data: GetTrackingData,
    className?: string,
    tile: number,
    Advert?: AdWithFormType,
};

const isNativeAdvert = (adJson) => !!(adJson && adJson.adtype);

const logError = (message: string, error?: Error) => {
    appLogger.error(message)(error);
};

type EnquiryFormPrefill = {
    name?: string,
    email?: string,
    phoneNumber?: string,
    bedroomConfig?: string,
    budgetConfig?: string,
    buyTimePeriodConfig?: string,
    updatedAt?: number,
};

const getPrefillDataForEnquiryForm = () => {
    const storedData = clientStorage.getItem(PREFILL_DATA_KEY);
    return storedData != undefined ? JSON.parse(atob(storedData)) : undefined;
};

const clientStorageForPrefill = () => {
    return {
        getPrefillData: (): EnquiryFormPrefill | typeof undefined => {
            if (createBrowserUser().isSignedIn) {
                return getPrefillDataForEnquiryForm();
            }
            clientStorage.removeItem(PREFILL_DATA_KEY);
        },
        setPrefillData: (data: EnquiryFormPrefill): void => {
            if (!createBrowserUser().isSignedIn) {
                return;
            }
            const prefillData = getPrefillDataForEnquiryForm();
            return clientStorage.setItem(PREFILL_DATA_KEY, btoa(JSON.stringify({ ...prefillData, ...data })));
        },
    };
};

const StripAd = ({ data, className = '', tile, Advert = AdWithForm }: Props) => {
    const position = `strip${tile}`;
    const initialClassName = `advert strip-ad ${className}`.trim();
    const trackingData = data.getTrackingData();
    const [adClassName, setAdClassName] = useState<string>(initialClassName);
    const cacheKey = useAdKey(position, trackingData);
    const layout = useLayout(trackingData);

    const stripAdCallback = (adJson) => {
        isNativeAdvert(adJson) ? setAdClassName(`${initialClassName} native`) : setAdClassName(initialClassName);
    };
    const isMobileScreen = !(layout === L || layout === XL);
    const sizes = !isMobileScreen
        ? ['640x200', '640x380', 'native']
        : ['300x50', '320x50', '300x250', '320x272', 'native'];

    // The below third-party component Advert uses the track function, but
    // experiment is turned off so we pass in a blank experiment id
    const { track } = useExperiment('');

    return (
        layout &&
        cacheKey && (
            <ErrorBoundaryNewRelicReporter component="StripAd">
                <FeatureToggle toggleName="nmal">
                    {(isNMALToggleOn) => (
                        <Advert
                            isNMALToggleOn={isNMALToggleOn}
                            adKey={cacheKey}
                            logError={logError}
                            clientStorage={clientStorageForPrefill()}
                            appElementId="#argonaut-wrapper"
                            id={`advert-${position}`}
                            appName={APP_NAME}
                            cacheKey={layout === S ? cacheKey : undefined}
                            position={position}
                            className={adClassName}
                            dataLayer={trackingData}
                            onLoad={stripAdCallback}
                            sizes={sizes}
                            pinAdsConfig={{ isMobileScreen }}
                            autoHide={true}
                            heading="left"
                            isPrebid={true}
                            renderPrebidNativeSize={getSrpPrebidNativeSize(isMobileScreen, position)}
                            customParams={{
                                rb: 'true',
                                posttags: 'adkit',
                                kw: 'pb_stripad',
                            }}
                            abTest={ABTestDeprecated}
                            trackExperiment={track}
                        />
                    )}
                </FeatureToggle>
            </ErrorBoundaryNewRelicReporter>
        )
    );
};

export default StripAd;
