/* @flow */
import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { GetMarketInsights, MarketInsights } from '../../../models/lexa';
import { getBaseUrl, getAnchor, getQueryParams } from '../marketInsightsUtils';
import type { SourceParam } from '../types';
import DetailsMarketInsightsData from './DetailsMarketInsightsData.graphql';

type AdaptedData = {
    title: ?string,
    description: ?string,
    buttonText: ?string,
    suburbProfileUrl: ?string,
};

const dataAdapter =
    (param: SourceParam) =>
    (marketInsights: ?MarketInsights): AdaptedData => {
        const { title, description, buttonText, link } = marketInsights?.richCta || {};

        const sourcePage = `sourcePage=${param.sourcePage}`;
        const sourceElement = `&sourceElement=${param.sourceElement}`;

        const baseSuburbProfileUrl = getBaseUrl(link?.href || '');
        const suburbProfileUrlAnchor = getAnchor(link?.href || '');
        const suburbProfileUrlQueryParams = getQueryParams(link?.href || '');

        const url = `${baseSuburbProfileUrl}?${sourcePage}${sourceElement}${suburbProfileUrlQueryParams}${suburbProfileUrlAnchor}`;

        return {
            title,
            description,
            buttonText,
            suburbProfileUrl: link?.href && url,
        };
    };

export default (param: SourceParam): ((GetMarketInsights) => AdaptedData) =>
    compose(dataAdapter(param), getFragmentData(DetailsMarketInsightsData), (data: GetMarketInsights) =>
        data.getMarketInsights()
    );
