/* @flow */
import React from 'react';
import adaptLexaData from './adaptLexaData';
import type { GetTrackingData, GetResidentialListing } from '../../../models/lexa';
import type { AdType } from '@media/ad-kit';
import { LAYOUT_DEFINITION } from '../../../client/device-capability';
import { Ad } from '@media/ad-kit';
import useAdKey from '../useAdKey';
import useLayout from '../useLayout';
import { APP_NAME } from '../adParams';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';
import './styles.scss';
import { getPdpPrebidNativeSize } from '@media/advert-configs-plw';

const { S, M } = LAYOUT_DEFINITION;
export type Props = {
    data: GetTrackingData & GetResidentialListing,
    Advert?: AdType,
};

const FloorMedRec = ({ data, Advert = Ad }: Props) => {
    const position = 'medrec1';
    const trackingData = data.getTrackingData();
    const adKey = useAdKey(position, trackingData);
    const layout = useLayout(trackingData);
    const { showFloorMedRec } = adaptLexaData(data);

    return (
        showFloorMedRec &&
        (layout === S || layout === M) &&
        adKey && (
            <ErrorBoundaryNewRelicReporter component="ResultsFloorMedRecAd">
                <Advert
                    adKey={adKey}
                    id={`advert-${position}`}
                    appName={APP_NAME}
                    position={position}
                    className="floor-medrec-advert"
                    dataLayer={trackingData}
                    sizes={['300x50', '320x50', '320x100', '300x250', 'native']}
                    autoHide={true}
                    heading="center"
                    isPrebid={true}
                    renderPrebidNativeSize={getPdpPrebidNativeSize(true, position)}
                    customParams={{ posttags: 'adkit', hb: '1' }}
                />
            </ErrorBoundaryNewRelicReporter>
        )
    );
};

export default FloorMedRec;
