/* @flow */
import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { GetMarketInsights } from '../../../models/lexa';
import { getBaseUrl, getAnchor, getQueryParams } from '../marketInsightsUtils';
import type { SourceParam } from '../types';
import ResultsMarketInsightsData from './ResultsMarketInsightsData.graphql';

type RequiredData = {
    simpleCta?: {
        formattedTitle: string,
        link: {
            href: string,
        },
    },
};

type AdaptedData = {
    title: ?string,
    suburbProfileUrl: ?string,
};

const dataAdapter =
    (param: SourceParam) =>
    (marketInsights: ?RequiredData): AdaptedData => {
        const marketInsightsData = marketInsights?.simpleCta || {};

        const sourcePage = `sourcePage=${param.sourcePage}`;
        const sourceElement = `&sourceElement=${param.sourceElement}`;

        const baseSuburbProfileUrl = getBaseUrl(marketInsightsData?.link?.href || '');
        const suburbProfileUrlAnchor = getAnchor(marketInsightsData?.link?.href || '');
        const suburbProfileUrlQueryParams = getQueryParams(marketInsightsData?.link?.href || '');

        const url = `${baseSuburbProfileUrl}?${sourcePage}${sourceElement}${suburbProfileUrlQueryParams}${suburbProfileUrlAnchor}`;

        return {
            title: marketInsightsData?.formattedTitle,
            suburbProfileUrl: marketInsightsData?.link && url,
        };
    };

export default (param: SourceParam): ((GetMarketInsights) => AdaptedData) =>
    compose(dataAdapter(param), getFragmentData(ResultsMarketInsightsData), (data: GetMarketInsights) =>
        data.getMarketInsights()
    );
