/* @flow */

import React from 'react';
import type { GetTrackingData } from '../../../models/lexa';
import { LAYOUT_DEFINITION } from '../../../client/device-capability';
import { Ad } from '@media/ad-kit';
import type { AdType } from '@media/ad-kit';
import { APP_NAME } from '../adParams';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';
import useLayout from '../useLayout';
import useAdKey from '../useAdKey';
import { getSrpPrebidNativeSize } from '@media/advert-configs-plw';

const { L, XL } = LAYOUT_DEFINITION;
export type Props = {
    data: GetTrackingData,
    className?: string,
    Advert?: AdType,
};

const ResultsSkyScraper = ({ data, className = '', Advert = Ad }: Props) => {
    const position = 'skyscraper';
    const trackingData = data.getTrackingData();
    const adKey = useAdKey(position, trackingData);
    const layout = useLayout(trackingData);

    return (
        (layout === L || layout === XL) &&
        adKey && (
            <ErrorBoundaryNewRelicReporter component="ResultsSkyScraper">
                <Advert
                    adKey={adKey}
                    id={`advert-${position}`}
                    appName={APP_NAME}
                    position={position}
                    className={`advert ${className}`.trim()}
                    dataLayer={trackingData}
                    sizes={['120x600', '300x600', '160x600', '300x250']}
                    autoHide={true}
                    isPrebid={true}
                    renderPrebidNativeSize={getSrpPrebidNativeSize(false, position)}
                    customParams={{ rb: 'true', posttags: 'adkit', hb: '1' }}
                />
            </ErrorBoundaryNewRelicReporter>
        )
    );
};

export default ResultsSkyScraper;
