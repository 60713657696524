/* @flow */

import type { TealiumData } from './dataLayerFormat';

export type UtagFn = (data_object: TealiumData, callback?: () => void, uid_array?: Array<string>) => void;
export type UtagLib = {
    view: UtagFn,
    link: UtagFn,
    data: {},
};
export type TealiumLoader = Promise<UtagLib>;

export function loader<U>(interval: number, check: () => ?U): Promise<U> {
    return new Promise((resolve, reject) => {
        function checkLoaded() {
            try {
                const loaded = check();
                if (loaded) {
                    return resolve(loaded);
                }
                setTimeout(checkLoaded, interval);
            } catch (e) {
                return reject(e);
            }
        }
        checkLoaded();
    });
}

function getUtag(): TealiumLoader {
    return loader(500, (): ?UtagLib => {
        if (typeof window === 'undefined') {
            return {
                view: () => {},
                link: () => {},
                data: {},
            };
        }
        /*eslint-disable no-undef*/
        // flow:disable global
        if (typeof utag === 'object') {
            return utag;
        }
    });
}

const promise: TealiumLoader = getUtag();
export default promise;
