/* @flow */
import type { Channel } from '../Channel';
import type { BreadcrumbItem } from './types';
import type { PageType } from '../../components/navigation/reducer';
import type {
    Address,
    BuyResidentialListing,
    RentResidentialListing,
    SoldResidentialListing,
    LexaPropertyType,
    ProjectProfileListingLinks,
    ResolvedQuery,
} from '../lexa';
import NEW_HOMES_CHANNEL from '../NewHomesChannel';
import { SEARCH_RESULTS } from '../../components/navigation/reducer';
import getChannelItem from './getChannelItem';
import getStateItem from './getStateItem';
import getSuburbItem from './getSuburbItem';
import getPropertyTypeItem from './getPropertyTypeItem';
import getProjectNameItem from './getProjectNameItem';
import getAddressItem from './getAddressItem';
import {
    getNewHomesChannelItem,
    getNewHomesStateItem,
    getNewHomesSuburbItem,
    propertyTypeToNewHomesChannel,
} from './getNewHomesItems';
import getResultsLocationItems from './results-items/getLocationItems';
import getResultsPropertyTypeItem from './results-items/getPropertyTypeItem';
import getResultsSearchModeItem from './results-items/getSearchModeItem';
import getResultsBedroomItem from './results-items/getBedroomItem';
import { SearchQuery } from '../../util/rea-urls';

export { transformToExternalBreadcrumbItem, transformToInternalBreadcrumbItem } from './transformBreadcrumbItem';
export { matchesNewHomesURL, matchesBuySoldLandingPageURL } from './externalUrlRegex';

type RequiredPropsForResidential = BuyResidentialListing | RentResidentialListing | SoldResidentialListing;

type RequiredPropsForProjectProfile = {
    name: string,
    address: Address,
    propertyType: LexaPropertyType,
    _links: ProjectProfileListingLinks,
};

export const getResidentialBreadcrumbItems =
    (channel: Channel) =>
    (listing: RequiredPropsForResidential): Array<BreadcrumbItem> => {
        const { id, address, propertyType, _links } = listing;
        return [
            getChannelItem(channel),
            getStateItem(channel, address),
            getSuburbItem(channel, address),
            getPropertyTypeItem(channel, address, propertyType),
            listing.__typename === 'BuyResidentialListing' && listing.parent
                ? getProjectNameItem(listing.parent.name, listing.parent._links)
                : null,
            getAddressItem(address, _links, id),
        ].filter(Boolean);
    };

export const getProjectProfileBreadcrumbItems =
    (fromPage: PageType) =>
    ({ name, address, propertyType, _links }: RequiredPropsForProjectProfile): Array<BreadcrumbItem> => {
        const isFromResult = fromPage === SEARCH_RESULTS;
        const isRetireProject = propertyType.id === 'project-retire';

        if (isFromResult || isRetireProject) {
            const channel = 'buy';
            return [
                getChannelItem(channel),
                getStateItem(channel, address),
                getSuburbItem(channel, address),
                getProjectNameItem(name, _links),
            ].filter(Boolean);
        }
        const channel = propertyTypeToNewHomesChannel[propertyType.id];
        return [
            getNewHomesChannelItem(NEW_HOMES_CHANNEL.NEW_HOMES),
            getNewHomesChannelItem(channel),
            getNewHomesStateItem(channel, address),
            getNewHomesSuburbItem(channel, address),
            getProjectNameItem(name, _links),
        ];
    };

export const getSearchResultBreadcrumbItems =
    (channel: Channel, { filters: { propertyTypes, bedroomsRange }, searchMode }: SearchQuery) =>
    ({ localities }: ResolvedQuery): Array<BreadcrumbItem> => {
        const items = [
            getChannelItem(channel),
            ...getResultsLocationItems(channel, localities),
            getResultsPropertyTypeItem(channel, localities, propertyTypes),
            searchMode.includes('-times')
                ? getResultsSearchModeItem(channel, localities, propertyTypes, searchMode)
                : getResultsBedroomItem(channel, localities, propertyTypes, bedroomsRange),
        ].filter(Boolean);
        return items.length > 1 ? items : [];
    };
