/* @flow */
import type { BreadcrumbSchemaMarkup } from './types';
import type { BreadcrumbItem } from '../breadcrumb/types';
import { PRODUCTION_DESKTOP_HOST, PRODUCTION_MSITE_HOST } from '../../config';

const getSanitisedHost = (host: string): string => {
    return host === PRODUCTION_MSITE_HOST ? PRODUCTION_MSITE_HOST : PRODUCTION_DESKTOP_HOST;
};

export default (host: string, items: Array<BreadcrumbItem>): ?BreadcrumbSchemaMarkup => {
    if (!items.length) return null;
    return {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: items.map((item, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: item.title,
            item: item.isCanonicalUrl ? `${item.to}` : `https://${getSanitisedHost(host)}${item.to}`,
        })),
    };
};
