/* @flow */
import React from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import type { GetMarketInsights } from '../../../models/lexa';

type Props = {
    data: GetMarketInsights,
    sourcePage: string,
    sourceElement: string,
};

export default ({ data, sourcePage, sourceElement }: Props) => (
    <View {...adaptLexaData({ sourcePage, sourceElement })(data)} />
);
