/* @flow */
import React, { useEffect } from 'react';
import adaptLexaData from './adaptLexaData';
import type { GetTrackingData, GetResidentialListing } from '../../../models/lexa';
import set from 'lodash/set';
import './styles.scss';
import { ScriptAd } from '@media/ad-kit';
import useAdKey from '../useAdKey';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';
import { APP_NAME } from '../adParams';
import type { ScriptAdType } from '@media/ad-kit';

type Props = {
    data: GetTrackingData & GetResidentialListing,
    className?: string,
    ScriptAdvert?: ScriptAdType,
};

const VI_VARS = ['REA', 'VI', 'CalculatorParams'];

const CalculatorAdvert = ({ data, className = '', ScriptAdvert = ScriptAd }: Props) => {
    const position = 'mortgage';
    const trackingData = data.getTrackingData();
    const adKey = useAdKey(position, trackingData);
    const adaptedData = adaptLexaData(data);
    useEffect(() => {
        set(window, VI_VARS, adaptedData);
    });

    return (
        adKey && (
            <ErrorBoundaryNewRelicReporter component="CalculatorAdvert">
                <div id="advert-widecalc_mortgage_calc" className="mortgage-calculator-advert">
                    <ScriptAdvert
                        adKey={adKey}
                        appName={APP_NAME}
                        position={position}
                        className={`advert ${className}`.trim()}
                        dataLayer={trackingData}
                        sizes={['650x117']}
                        customParams={{ posttags: 'adkit' }}
                    />
                </div>
            </ErrorBoundaryNewRelicReporter>
        )
    );
};

export default CalculatorAdvert;
