/* @flow */
import React, { useMemo } from 'react';
import { LAYOUT_DEFINITION } from '../../../client/device-capability';
import type { GetTrackingData } from '../../../models/lexa';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';
import { Ad, Prebid as AdKitPrebid } from '@media/ad-kit';
import type { AdType, PrebidType } from '@media/ad-kit';
import { APP_NAME } from '../adParams';
import useLayout from '../useLayout';
import useAdKey from '../useAdKey';
import { createMapskyConfig, getPdpPrebidNativeSize } from '@media/advert-configs-plw';

export type Props = {
    data: GetTrackingData,
    Advert?: AdType,
    Prebid?: PrebidType,
    className?: string,
};

const { L, XL } = LAYOUT_DEFINITION;

const MapSkyscraper = ({ Advert = Ad, Prebid = AdKitPrebid, data, className = '' }: Props) => {
    const position = 'mapsky';
    const trackingData = data.getTrackingData();
    const adKey = useAdKey(position, trackingData);
    const layout = useLayout(trackingData);

    const isLargeOrExtraLargeLayout = layout === L || layout === XL;

    const prebidConfig = useMemo(() => {
        if (isLargeOrExtraLargeLayout) {
            return createMapskyConfig();
        }
        return null;
    }, [isLargeOrExtraLargeLayout]);

    return (
        adKey &&
        isLargeOrExtraLargeLayout && (
            <ErrorBoundaryNewRelicReporter component="mapSkyscraper">
                <React.Fragment>
                    {prebidConfig && <Prebid dataLayer={trackingData} prebidConfig={prebidConfig} appName={APP_NAME} />}
                    <Advert
                        adKey={adKey}
                        appName={APP_NAME}
                        position={position}
                        id="advert-map-skyscraper"
                        className={`advert ${className}`.trim()}
                        dataLayer={trackingData}
                        sizes={['120x600', '160x600']}
                        autoHide={true}
                        customParams={{ posttags: 'adkit', hb: '1' }}
                        isPrebid={true}
                        renderPrebidNativeSize={getPdpPrebidNativeSize(false, position)}
                    />
                </React.Fragment>
            </ErrorBoundaryNewRelicReporter>
        )
    );
};

export default MapSkyscraper;
