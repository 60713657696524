/* @flow */

import React, { useState, useEffect, useMemo } from 'react';
import { LAYOUT_DEFINITION } from '../../../client/device-capability';
import type { Layout } from '../../../client/device-capability';
import type { TrackingData } from '../../../models/lexa';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';
import { Ad, Prebid as AdKitPrebid } from '@media/ad-kit';
import { createPhotoskyConfig, createPhotosky2Config } from '@media/advert-configs-plw';
import type { AdType, PrebidType } from '@media/ad-kit';
import { APP_NAME } from '../adParams';
import useLayout from '../useLayout';
import styled from 'styled-components';

export type Props = {
    trackingData?: TrackingData,
    Advert?: AdType,
    Prebid?: PrebidType,
    className?: string,
    skyscraperIndex?: number,
};

const { S, M, L, XL } = LAYOUT_DEFINITION;

const StyledAd = styled(Ad)`
    ${({ isVisible }) => (isVisible ? '' : 'display: none;')}
`;

const getLayoutValue = (layout: Layout | null): null | 'S' | 'M' | 'L' | 'XL' => {
    switch (layout) {
        case S:
            return 'S';
        case M:
            return 'M';
        case L:
            return 'L';
        case XL:
            return 'XL';
        default:
            return null;
    }
};

const MediaViewerSkyscraperAd = ({
    className = '',
    Advert = StyledAd,
    Prebid = AdKitPrebid,
    trackingData,
    skyscraperIndex,
}: Props) => {
    const layout = useLayout(trackingData);
    const [ads, setAds] = useState([]);
    const isSmallLayout = layout === S;

    const prebidConfig = useMemo(() => {
        if (!layout || isSmallLayout) {
            return null;
        }

        const photoskyLayout = getLayoutValue(layout);

        return skyscraperIndex === 0 ? createPhotoskyConfig(photoskyLayout) : createPhotosky2Config(photoskyLayout);
    }, [layout, isSmallLayout, skyscraperIndex]);

    const toggleToNewSkyscraper = (keepKey) => (_, adLoaded) => {
        if (!adLoaded) {
            return;
        }

        setAds((ads) => ads.filter(({ key }) => key === keepKey).map((ad) => ({ ...ad, visible: true })));
    };

    useEffect(() => {
        if (isSmallLayout) {
            return;
        }

        setAds((ads) => [
            ...ads,
            {
                visible: skyscraperIndex === 0,
                key: skyscraperIndex,
            },
        ]);
    }, [skyscraperIndex, isSmallLayout]);

    return (
        !isSmallLayout && (
            <ErrorBoundaryNewRelicReporter component="MediaViewerSkyscraperAd">
                {prebidConfig && <Prebid dataLayer={trackingData} prebidConfig={prebidConfig} appName={APP_NAME} />}
                {ads.map(({ visible, key }) => {
                    const position = key === 0 ? 'photosky' : 'photosky2';
                    return (
                        <Advert
                            isPrebid={true}
                            key={key}
                            adKey="key"
                            appName={APP_NAME}
                            position={position}
                            id={`advert-${position}`}
                            className={className || ''}
                            dataLayer={trackingData}
                            sizes={layout === XL ? ['160x600', '300x600'] : ['160x600']}
                            section="details"
                            autoHide={true}
                            onLoad={key === 0 ? undefined : toggleToNewSkyscraper(key)}
                            isVisible={visible}
                            customParams={{ posttags: 'adkit', hb: '1' }}
                        />
                    );
                })}
            </ErrorBoundaryNewRelicReporter>
        )
    );
};

export default MediaViewerSkyscraperAd;
