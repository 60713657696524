/* @flow */
import type { ResolvedLocality, LocationHierarchy } from '../../lexa';

const statePrecision = 'STATE';
const subLocationPrecisions = ['SUBURB', 'POSTAL_CODE', 'REGION'];
export const acceptablePrecisions = [statePrecision, ...subLocationPrecisions];

const isSameState = (states) => states.every((state) => state.name === states[0].name);

const processMultipleHierarchies = (hierarchies: Array<LocationHierarchy>): LocationHierarchy => {
    const states = hierarchies
        .map((hierarchy) => hierarchy.find((locality) => locality.precision === statePrecision))
        .filter(Boolean);
    const state = isSameState(states) ? states[0] : null;
    return [state].filter(Boolean);
};

const toHierarchy = (locality: ResolvedLocality): LocationHierarchy =>
    [...locality.parents, locality].filter((locality) => acceptablePrecisions.includes(locality.precision));

const getLocationHierarchy = (localities: Array<ResolvedLocality>): LocationHierarchy => {
    const hierarchies = localities.map(toHierarchy);
    return hierarchies.length === 1 ? hierarchies[0] : processMultipleHierarchies(hierarchies);
};

export default getLocationHierarchy;
