/* @flow */
import React, { useState } from 'react';
import { ScriptAd } from '@media/ad-kit';
import type { ScriptAdType } from '@media/ad-kit';
import type { GetTrackingData } from '../../../models/lexa';
import useLayout from '../useLayout';
import useAdKey from '../useAdKey';
import { LAYOUT_DEFINITION } from '../../../client/device-capability';
import { APP_NAME } from '../adParams';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';

import './styles.scss';

export type Props = {|
    data: GetTrackingData,
    className?: string,
    ScriptAdvert?: ScriptAdType,
|};

const { L, XL } = LAYOUT_DEFINITION;

const WideCalc = ({ data, className = '', ScriptAdvert = ScriptAd }: Props) => {
    const [isAdSuccessfullyLoaded, setIsAdSuccessfullyLoaded] = useState(false);
    const position = 'widecalc';
    const trackingData = data.getTrackingData();
    const layout = useLayout(trackingData);
    const adKey = useAdKey(position, trackingData);

    const onLoad = (_, adLoaded) => {
        setIsAdSuccessfullyLoaded(adLoaded);
    };

    const classes = `advert ${!isAdSuccessfullyLoaded ? 'advert-widecalc-hidden ' : ''}${className}`.trim();

    return (
        adKey &&
        (layout === L || layout === XL) && (
            <ErrorBoundaryNewRelicReporter component="WideCalc">
                <ScriptAdvert
                    adKey={adKey}
                    appName={APP_NAME}
                    position={position}
                    id="advert-widecalc"
                    className={classes}
                    dataLayer={trackingData}
                    sizes={['650x117']}
                    autoHide={true}
                    onLoad={onLoad}
                    customParams={{ posttags: 'adkit' }}
                />
            </ErrorBoundaryNewRelicReporter>
        )
    );
};

export default WideCalc;
