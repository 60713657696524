/* @flow */
import React from 'react';
import WidgetView from './View';
import type { GetTrackingData, GetResidentialListing } from '../../../models/lexa';
import type { AdType } from '@media/ad-kit';
import adaptLexaData from './adaptLexaData';

export type Props = {
    data: GetTrackingData & GetResidentialListing,
    pos: number,
    Advert?: AdType,
};

const WidgetAd = ({ data, pos, Advert }: Props) => {
    return <WidgetView {...adaptLexaData(data)} data={data} pos={pos} Advert={Advert} />;
};

export default WidgetAd;
