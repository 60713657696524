/* @flow */
import type { BreadcrumbItem } from './types';
import type { Address, ListingLinks } from '../lexa';

export default (
    { display: { shortAddress } }: Address,
    { canonical: { path } }: ListingLinks,
    id: string
): BreadcrumbItem => ({
    to: path,
    title: shortAddress || id,
});
