/* @flow */
import type { Channel } from '../../Channel';
import type { ResolvedLocality } from '../../lexa';
import type { BreadcrumbItem } from '../types';
import getLocalityItem from '../getLocalityItem';
import getLocationHierarchy from './getLocationHierarchy';

const getLocationItems = (channel: Channel, localities: Array<ResolvedLocality>): Array<BreadcrumbItem> =>
    getLocationHierarchy(localities).map(getLocalityItem(channel));

export default getLocationItems;
