/* @flow */
import React from 'react';
import RenovationCalculatorView from './View';
import adaptLexaData from './adaptLexaData';
import type { GetTrackingData, GetResidentialListing } from '../../../models/lexa';
import type { RuiAdvertorial } from '../types';

export type Props = {
    data: GetTrackingData & GetResidentialListing,
    Advertorial?: RuiAdvertorial,
    page: string,
};

const RenovationCalculator = ({ data, Advertorial, page }: Props) => {
    return <RenovationCalculatorView {...adaptLexaData(data)} data={data} Advertorial={Advertorial} page={page} />;
};

export default RenovationCalculator;
