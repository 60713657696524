/* @flow */
import { useEffect } from 'react';
import type { TrackingData } from '../../../models/lexa';
import { useAdResponse } from '@media/ad-kit';
import { APP_NAME } from '../adParams';

export type Props = {
    trackingData: TrackingData,
    onLoad: ({ adProperties: { [key: string]: any } | string | null, isAdLoaded: boolean }) => void,
    className?: string,
};

const PhotoGalleryAd = ({ trackingData, onLoad }: Props) => {
    const position = 'photogallery';

    const { isLoading, sponsorData, isAdLoaded } = useAdResponse({
        appName: APP_NAME,
        position,
        section: 'details',
        sizes: ['native', '300x250'],
        customParams: { posttags: 'adkit' },
        dataLayer: trackingData,
        isPrebid: true,
    });

    useEffect(() => {
        if (isAdLoaded == undefined) {
            return;
        }

        onLoad({ adProperties: sponsorData, isAdLoaded });
    }, [isLoading, onLoad, sponsorData, isAdLoaded]);

    return null;
};

export default PhotoGalleryAd;
