import styled from 'styled-components';
import { Card, paletteHelper, Link, typographyHelper } from '@rea-group/construct-kit-core';
import { MAX_CONTENT_WITH_GUTTERS } from '../../../../styles/application/layout';

export const StyledCard = styled(Card)`
    box-shadow: none;
    padding: ${({ theme }) => theme.spacing.medium};
    background: ${paletteHelper('backgroundSecondaryBase')};
    display: grid;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.medium};
    grid-template-columns: 1fr;
    @media (min-width: ${MAX_CONTENT_WITH_GUTTERS}px) {
        grid-template-columns: 1fr max-content;
    }
`;

export const TitleLink = styled(Link)`
    ${typographyHelper('title02')};
    color: ${paletteHelper('textPrimary')};

    &:hover {
        color: ${paletteHelper('textPrimary')};
        text-decoration: none;
    }
`;
