/* @flow */
import type { ResolvedLocality } from '../../lexa';
import replacePlusWithSpace from '../../../util/string/replacePlusWithSpace';
import getLocationHierarchy from './getLocationHierarchy';

const getLocationUrlValue = (localities: Array<ResolvedLocality>) => {
    const hierarchy = getLocationHierarchy(localities);
    return hierarchy.length > 0 ? [replacePlusWithSpace(hierarchy[hierarchy.length - 1].urlValue)] : [];
};

export default getLocationUrlValue;
