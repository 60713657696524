/* @flow */
import React from 'react';
import UrlChangeObserver from '../../UrlChangeObserver';
import type { RouterLocation } from '../../../util/routerTypes';
import { createBrowserUser } from '@realestate.com.au/user';

type Props = {|
    location: RouterLocation,
|};

const onUrlChange = () => {
    if (createBrowserUser().isSignedIn && typeof window.refreshLiveRampEnvelope === 'function') {
        window.refreshLiveRampEnvelope();
    }
};

const LiveRampATS = ({ location }: Props) => (
    <UrlChangeObserver url={location} onUrlChange={onUrlChange} triggerOnMount={true}>
        {null}
    </UrlChangeObserver>
);

export default LiveRampATS;
