/* @flow */

import { useEffect, useState } from 'react';
import { LAYOUT_DEFINITION } from '../client/device-capability/screenType';
import type { Node } from 'react';

type Props = {
    assumeSingleColumn?: boolean,
    children: (x: State) => Node,
};

type State = {|
    isSingleCol: boolean,
    isDoubleCol: boolean,
|};

function useMedia(queries: Array<string>, fallback: boolean) {
    const [state, setState] = useState(fallback);

    useEffect(() => {
        if (typeof window.matchMedia !== 'function') return () => {};

        const listener = (e) => setState(e.matches);
        const mql = window.matchMedia(queries.join(','));
        mql.addListener(listener);
        setState(mql.matches);
        return () => {
            mql.removeListener(listener);
        };
    }, [queries]);

    return state;
}

const largeOrAbove = [LAYOUT_DEFINITION.L, LAYOUT_DEFINITION.XL];

export default function SingleOrDoubleColumn(props: Props) {
    const { assumeSingleColumn = true, children } = props;
    const isLargeOrAbove = useMedia(largeOrAbove, !assumeSingleColumn);

    return children({
        isSingleCol: !isLargeOrAbove,
        isDoubleCol: isLargeOrAbove,
    });
}
