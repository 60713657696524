/* @flow */
import type { Channel } from '../../Channel';
import type { ResolvedLocality } from '../../lexa';
import type { SearchPropertyType } from '../../propertyType';
import type { BreadcrumbItem } from '../types';
import { type Range } from '../../../util/rea-urls/lib/models/QueryFilters';
import { QueryFilters, SearchQuery } from '../../../util/rea-urls';
import { acceptablePrecisions } from './getLocationHierarchy';
import getLocationUrlValue from './getLocationUrlValue';

const acceptablePropertyTypes = ['house', 'unit apartment', 'townhouse', 'villa'];

const isAcceptablePrecisions = (localities) =>
    localities.length === 0 || (localities.length === 1 && acceptablePrecisions.includes(localities[0].precision));

const isAcceptablePropertyType = (propertyTypes) =>
    propertyTypes.length === 0 || (propertyTypes.length === 1 && acceptablePropertyTypes.includes(propertyTypes[0]));

const isAcceptableBedroom = (bedroomsRange) => !isNaN(bedroomsRange.minimum);

const showBedroom = (
    localities: Array<ResolvedLocality>,
    propertyTypes: Array<SearchPropertyType>,
    bedroomsRange: Range
) => {
    if (localities.length <= 1 && propertyTypes.length <= 1) {
        return (
            isAcceptablePrecisions(localities) &&
            isAcceptablePropertyType(propertyTypes) &&
            isAcceptableBedroom(bedroomsRange)
        );
    }
    return false;
};

const getBedroomText = (bedroomsRange) => {
    if (bedroomsRange.minimum) {
        return `${bedroomsRange.minimum} Bedroom${parseInt(bedroomsRange.minimum) >= 2 ? 's' : ''}`;
    }
    return '';
};

const getBedroomItem = (
    channel: Channel,
    localities: Array<ResolvedLocality>,
    propertyTypes: Array<SearchPropertyType>,
    bedroomsRange: Range
): ?BreadcrumbItem => {
    if (showBedroom(localities, propertyTypes, bedroomsRange)) {
        const locations = getLocationUrlValue(localities);
        return {
            to: new SearchQuery({
                channel,
                filters: new QueryFilters({
                    location: { searchLocations: locations },
                    propertyTypes: propertyTypes.slice(0, 1),
                    bedroomsRange: { minimum: bedroomsRange.minimum },
                }),
                searchMode: 'list',
            }).toUrl(),
            title: getBedroomText(bedroomsRange),
        };
    }
    return null;
};

export default getBedroomItem;
