/* @flow */
import type { Channel } from '../../Channel';
import type { ResolvedLocality } from '../../lexa';
import type { SearchPropertyType } from '../../propertyType';
import type { BreadcrumbItem } from '../types';
import { QueryFilters, SearchQuery } from '../../../util/rea-urls';
import { propertyTypesValueToLabel } from '../../config/propertyTypes';
import getLocationUrlValue from './getLocationUrlValue';

const getPropertyTypeItem = (
    channel: Channel,
    localities: Array<ResolvedLocality>,
    propertyTypes: Array<SearchPropertyType>
): ?BreadcrumbItem => {
    if (propertyTypes.length === 1) {
        const locations = getLocationUrlValue(localities);
        return {
            to: new SearchQuery({
                channel,
                filters: new QueryFilters({
                    location: { searchLocations: locations },
                    propertyTypes: [propertyTypes[0]],
                }),
                searchMode: 'list',
            }).toUrl(),
            title: propertyTypesValueToLabel(channel)[propertyTypes[0]],
        };
    }
    return null;
};

export default getPropertyTypeItem;
