/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type {
    GetResidentialListing,
    ResidentialListingViewConfiguration,
    ResidentialListing,
} from '../../../models/lexa';
import FloorMedRec from './FloorMedRec.graphql';

type AdaptedProps = {
    showFloorMedRec: boolean,
};

type RequiredProps = {
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter = ({ viewConfiguration }: RequiredProps): AdaptedProps => {
    return {
        showFloorMedRec: !!viewConfiguration.details.adverts.floorMedRec,
    };
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, AdaptedProps>(
    dataAdapter,
    getFragmentData(FloorMedRec),
    (data) => data.getListing()
);
