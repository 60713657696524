/* @flow */
import type { BreadcrumbItem } from './types';
import type { Address } from '../lexa';
import SearchQuery from '../../util/rea-urls/lib/models/SearchQuery';
import QueryFilters from '../../util/rea-urls/lib/models/QueryFilters';
import type { Channel } from '../Channel';

export default (channel: Channel, { state, suburb, postcode }: Address): BreadcrumbItem => ({
    to: new SearchQuery({
        channel,
        filters: new QueryFilters({
            location: { searchLocations: [`${suburb}, ${state} ${postcode}`] },
        }),
        searchMode: 'list',
    }).toUrl(),
    title: suburb,
});
