/* @flow */

import React, { type ComponentType } from 'react';
import SingleOrDoubleColumn from './SingleOrDoubleColumn';

const DefaultFallbackComponent = () => null;

export default function singleColOnly(
    ComposedComponent: ComponentType<*>,
    FallbackComponent?: ComponentType<*> = DefaultFallbackComponent
): ComponentType<*> {
    return (props: any) => (
        <SingleOrDoubleColumn assumeSingleColumn={false}>
            {({ isSingleCol }) => (isSingleCol ? <ComposedComponent {...props} /> : <FallbackComponent {...props} />)}
        </SingleOrDoubleColumn>
    );
}
