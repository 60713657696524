/* @flow */
import type { BreadcrumbItem } from './types';
import type { Channel } from '../Channel';
import SearchQuery from '../../util/rea-urls/lib/models/SearchQuery';
import QueryFilters from '../../util/rea-urls/lib/models/QueryFilters';
import type { Locality } from '../lexa';

export default (channel: Channel) =>
    ({ urlValue, name }: Locality): BreadcrumbItem => ({
        to: new SearchQuery({
            channel,
            filters: new QueryFilters({
                location: { searchLocations: [urlValue.replace(/\+/g, ' ')] },
            }),
            searchMode: 'list',
        }).toUrl(),
        title: name,
    });
