/* @flow */
import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type {
    ResidentialListingViewConfiguration,
    ResidentialListing,
    GetResidentialListing,
} from '../../../models/lexa';
import RenovationCalculator from './RenovationCalculator.graphql';

type AdaptedProps = {
    showRenovationCalculator: boolean,
};

type RequiredProps = {
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter = ({ viewConfiguration }: RequiredProps): AdaptedProps => {
    return {
        showRenovationCalculator: !!viewConfiguration.details.adverts.renovationCalculator,
    };
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, AdaptedProps>(
    dataAdapter,
    getFragmentData(RenovationCalculator),
    (data) => data.getListing()
);
