/* @flow */
import getFragmentData from '../../../util/getFragmentData';

import { compose3 as compose } from '../../../util/functional/compose';
import type {
    GetResidentialListing,
    ResidentialListing,
    Address,
    Price,
    ResidentialListingMedia,
} from '../../../models/lexa';
import CalculatorAdvert from './CalculatorAdvert.graphql';

type RequiredProps = {
    address: Address,
    price: Price,
    media: ResidentialListingMedia,
};

type AdaptedProps = {|
    address: {
        postcode: string,
        streetAddress: string,
        suburb: string,
        state: string,
    },
    mainImage: string,
    price: string,
|};

const dataAdapter = ({ address, price, media }: RequiredProps): AdaptedProps => {
    return {
        address: {
            postcode: address.postcode,
            streetAddress: address.display.shortAddress || 'Address available on request',
            suburb: address.suburb,
            state: address.state,
        },
        mainImage: media.mainImage.templatedUrl,
        price: price.display,
    };
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, AdaptedProps>(
    dataAdapter,
    getFragmentData(CalculatorAdvert),
    (data) => data.getListing()
);
